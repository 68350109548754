
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import Campana from "../../../store/entities/OZONE/campana";
import DeduplicationInformation from "../../../store/entities/OZONE/deduplicationInformation";
import SelectCampana from "../campana/selectCampana.vue";
import GenerarExportacion from "../../../store/entities/OZONE/generarExportacion";
import { Moment } from "moment";

@Component({
  components: {
    SelectCampana,
  },
})
export default class Deduplicacion extends AbpBase {
  value: string = "";
  filterValue: string = "";
  info: DeduplicationInformation = null;
  data = [];

  visible: boolean = false;
  ready: boolean = true;
  completed: boolean = false;
  none: boolean = false;

  dCount: number = 0;
  dDone: number = 0;
  dType: string = null;
  dData = [];
  toDeduplicate = [];
  dCompleted = [];
  dCurrent = null;
  dIndex = 0;
  dIndexName = null;

  setFile(v) {
    this.value = v;
  }

  setFilterFile(v) {
    if (v == "*") {
      this.filterValue = null;
    } else {
      this.filterValue = v;
    }
  }

  get loading() {
    return this.$store.state.deduplicacion.loading;
  }

  get information() {
    return this.$store.state.deduplicacion.information;
  }

  get progress() {
    if (this.dCount == 0) {
      return 0;
    }

    var prog = ((this.dDone * 100) / this.dCount).toString();

    return parseInt(prog);
  }

  columns = [
    {
      dataIndex: "name",
      key: "name",
      slots: { title: "customTitle" },
      scopedSlots: { customRender: "name" },
    },
    {
      title: "Válidos",
      key: "ok",
      scopedSlots: { customRender: "ok" },
    },
    {
      title: "Pendientes",
      key: "pending",
      scopedSlots: { customRender: "pending" },
    },
    {
      title: "Duplicados",
      key: "dups",
      scopedSlots: { customRender: "dups" },
    },
    {
      title: "Deduplicar",
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];

  get files() {
    if (
      this.$store.state.deduplicacion.pendingFiles &&
      this.$store.state.deduplicacion.pendingFiles.length > 0
    ) {
      return this.$store.state.deduplicacion.pendingFiles.map((f) => ({
        text: `${f.workDate.split("T")[0]}`,
        value: f.workDate,
      }));
    }
  }

  async created() {
    await this.$store.dispatch({
      type: "deduplicacion/getPendingFiles",
    });
  }

  async process() {
    await this.$store.dispatch({
      type: "deduplicacion/startProcess",
      data: { workDate: this.value },
    });

    this.updateTable();
  }

  updateTable() {
    var needs = this.information.welcome;
    var sampling = this.information.sampling;

    this.data = [
      {
        key: 2,
        name: "Pack Gratis Bienvenida",
        count: needs.data.length,
        data: needs.data,
        action: needs.deduplicationIndex != null,
        did: needs.deduplicationId,
        index: needs.deduplicationIndex,
      },
      // {
      //   key: 3,
      //   name: "Muestras",
      //   count: sampling.data.length,
      //   data: sampling.data,
      //   action: sampling.deduplicationIndex != null,
      //   did: sampling.deduplicationId,
      //   index: sampling.deduplicationIndex,
      // },
    ];
  }

  async deduplicateSelf(index, did) {
    this.dData = [];
    this.toDeduplicate = [];
    this.completed = false;
    this.none = false;
    this.visible = true;
    this.dDone = 0;
    this.dIndexName = index;
    this.dType = "self";

    var data;
    if (index == "welcomepack") {
      data = this.$store.state.deduplicacion.information.welcome.data;
    } else if (index == "sampling") {
      data = this.$store.state.deduplicacion.information.sampling.data;
    }

    this.dData = await this.$store.dispatch({
      type: "deduplicacion/deduplicateSelf",
      data: {
        deduplicationId: did,
        data,
        deduplicationIndex: index,
      }
    });

    this.updateTable();
    var toDeduplicate = this.dData.filter(this.needDeduplication);

    if (toDeduplicate.length == 0) {
      this.none = true;
      this.dIndex = 0;
      this.dCurrent = null;
      this.completed = true;
      this.visible = false;
      this.dCompleted.push(this.dIndexName + this.dType);
    }

    this.toDeduplicate = toDeduplicate;
    this.dCount = toDeduplicate.length;
    this.dCurrent = this.toDeduplicate[this.dIndex];

    if (this.dIndexName == "welcomepack") {
      this.$store.commit("deduplicacion/setNeed", this.dData);
    } else if (this.dIndexName == "sampling") {
      this.$store.commit("deduplicacion/setSampling", this.dData);
    }
    
    this.updateTable();
  }

  async deduplicateBBDD(index, did) {
    this.completed = false;
    this.none = false;
    this.visible = true;
    this.dDone = 0;
    this.dIndexName = index;
    this.dType = "bbdd";
    this.toDeduplicate = [];

    var data = this.dData;
    this.dData = await this.$store.dispatch({
      type: "deduplicacion/deduplicateBBDD",
      data: {
        deduplicationId: did,
        data,
        deduplicationIndex: index,
      },
    });

    var toDeduplicate = this.dData.filter(this.needDeduplication);

    if (toDeduplicate.length == 0) {
      this.none = true;
      this.dIndex = 0;
      this.dCurrent = null;
      this.completed = true;
      this.visible = false;
      this.dCompleted.push(this.dIndexName + this.dType);
    }

    this.toDeduplicate = toDeduplicate;
    this.dCount = toDeduplicate.length;
    this.dCurrent = this.toDeduplicate[this.dIndex];
    if (this.dIndexName == "welcomepack") {
      this.$store.commit("deduplicacion/setNeed", this.dData);
    } 
    else if (this.dIndexName == "sampling") {
      this.$store.commit("deduplicacion/setSampling", this.dData);
    }
    
    this.updateTable();
  }

  needDeduplication(element) {
    return element.statusId === 3;
  }

  validate() {
    if (this.dIndex != null && this.toDeduplicate[this.dIndex] != null) {
      var order = this.toDeduplicate[this.dIndex].pgOrderReference;
      for (const obj of this.dData) {
        if (obj.pgOrderReference == order) {
          obj.statusId = 1;
          break;
        }
      }

      this.next();
    }
  }

  invalidate() {
    if (this.dIndex != null && this.toDeduplicate[this.dIndex] != null) {
      var order = this.toDeduplicate[this.dIndex].pgOrderReference;

      for (const obj of this.dData) {
        if (obj.pgOrderReference == order) {
          obj.statusId = 2;
          obj.subStatusId = 14;
          break;
        }
      }

      this.next();
    }
  }

  next() {
    var dLenght = this.toDeduplicate.length;
    this.dIndex = this.dIndex + 1;

    if (this.dIndexName == "welcomepack") {
      this.$store.commit("deduplicacion/setNeed", this.dData);
    } else if (this.dIndexName == "sampling") {
      this.$store.commit("deduplicacion/setSampling", this.dData);
    }

    this.updateTable();

    if (this.dIndex > dLenght - 1) {
      this.dIndex = 0;
      this.dCurrent = null;
      this.completed = true;
      this.visible = false;
      this.dCompleted.push(this.dIndexName + this.dType);

      //   if (this.dIndexName == "welcomepack" && this.dType == "bbdd") {
      //     this.$store.commit("deduplicacion/setNeed", this.dData);
      //   } else if (this.dIndexName == "sampling" && this.dType == "bbdd") {
      //     this.$store.commit("deduplicacion/setSampling", this.dData);
      //   }
    } else {
      this.dCurrent = this.toDeduplicate[this.dIndex];
      this.dDone = this.dDone + 1;

      



    }
  }

  mounted() {
    document.addEventListener("keydown", this.keyAction);
  }
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAction);
  }

  keyAction(e) {
    //F11
    if (e.keyCode === 122) {
      this.invalidate();
      e.preventDefault();
    }
    //F12
    if (e.keyCode === 123) {
      this.validate();
      e.preventDefault();
    }
  }

  async generate() {
    this.dData = await this.$store.dispatch({
      type: "deduplicacion/loadProcess",
      data: this.information,
    });
  }
}
